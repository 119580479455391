import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '',
      43114: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 1,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.smartcoin,
    contractAddress: {
      97: '', // let empty
      43114: '0x51e0D6c4e4fA79E44F40D321940f36beD4A4732E',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0046',
    sortOrder: 1,
    isFinished: false,
    
   },
   {
    sousId: 2,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.tmt,
    contractAddress: {
      97: '', // let empty
      43114: '0x41032def757Cc61623fa98B0c86caBEB9990B032',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00002256944',
    sortOrder: 2,
    isFinished: false,
   },
    
   
]

export default pools
