import { ChainId, Token } from '@pancakeswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const testnetTokens = {
  wbnb: new Token(
    TESTNET,
    '0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  cake: new Token(
    TESTNET,
    '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    18,
    'CAKE',
    'PancakeSwap Token',
    'https://pancakeswap.finance/',
  ),
  busd: new Token(
    TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  syrup: new Token(
    TESTNET,
    '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://pancakeswap.finance/',
  ),
  bake: new Token(
    TESTNET,
    '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    18,
    'BAKE',
    'Bakeryswap Token',
    'https://www.bakeryswap.org/',
  ),
}


export const mainnetTokens = {
  // avax: new Token (
  //   MAINNET,
  //   '0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b',
  //   18,
  //   'avax',
  //   'AVAX',
  //   'https://pancakeswap.finance/',
  // ),

  bnb: new Token (
    MAINNET,
    '0x264c1383EA520f73dd837F915ef3a732e204a493',
    18,
    'BNB',
    'Binance',
    'https://pancakeswap.finance/',
  ),

  wbnb: new Token (
    MAINNET,
    '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    18,
    'WAVAX',
    'wavax',
    'https://pancakeswap.finance/',
  ),

  cake: new Token(
    MAINNET,
    '0x84F4A604Ae84326A32EF4A80Ce25c55Eb6288C97',
    18,
    'TestDexy3',
    '$TDEXY3',
    'https://pancakeswap.finance/',
  ),

  eth: new Token(
    MAINNET,
    '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    18,
    'eth',
    'ETH',
    'https://pancakeswap.finance/',
  ),

  beth: new Token(
    MAINNET,
    '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    18,
    'beth',
    'WETH.e',
    'https://pancakeswap.finance/',
  ),

  usdt_e: new Token(
    MAINNET,
    '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    6,
    'usdt',
    'USDT.e',
    'https://pancakeswap.finance/',
  ),

  usdt: new Token(
    MAINNET,
    '0xde3A24028580884448a5397872046a019649b084',
    6,
    'usdt',
    'USDT',
    'https://pancakeswap.finance/',
  ),

  usdc: new Token(
    MAINNET,
    '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    6,
    'USDC.e',
    'usdc',
    'https://pancakeswap.finance/',
  ),

  btcb: new Token(
    MAINNET,
    '0x50b7545627a5162F82A992c33b87aDc75187B218',
    8,
    'btcb',
    'WBTC.e',
    'https://pancakeswap.finance/',
  ),

  joe: new Token(
    MAINNET,
    '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    8,
    'JOE',
    'joe',
    'https://pancakeswap.finance/',
  ),
  // ust: new Token(
  //   MAINNET,
  //   '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
  //   8,
  //   'ust',
  //   'UST',
  //   'https://pancakeswap.finance/',
  // ),

  dai: new Token(
    MAINNET,
    '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    18,
    'DAI.e',
    'dai',
    'https://pancakeswap.finance/',
  ),

  mimatic: new Token(
    MAINNET,
    '0x3B55E45fD6bd7d4724F5c47E0d1bCaEdd059263e',
    18,
    'miMatic',
    'mimatic',
    'https://pancakeswap.finance/',
  ),

  link: new Token(
    MAINNET,
    '0x5947bb275c521040051d82396192181b413227a3',
    18,
    'LINK.e',
    'Chainlink Token',
    'https://pancakeswap.finance/',
  ),

  smartcoin: new Token(
    MAINNET,
    '0xCC2f1d827b18321254223dF4e84dE399D9Ff116c',
    18,
    'SMRT',
    'SmartCoin',
    'https://pancakeswap.finance/',
  ),

  tmt: new Token(
    MAINNET,
    '0xf5760bbbC3565f6A513a9c20300a335A1250C57e',
    18,
    'TMT',
    'tmt',
    'https://pancakeswap.finance/',
  ),
  
  pangolin: new Token(
    MAINNET,
    '0x60781C2586D68229fde47564546784ab3fACA982',
    18,
    'PNG',
    'Pangolin',
    'https://pancakeswap.finance/',
  ),

  avaware: new Token(
    MAINNET,
    '0x78ea17559B3D2CF85a7F9C2C704eda119Db5E6dE',
    18,
    'AVE',
    'Avaware',
    'https://pancakeswap.finance/',
  ),

  cabbage: new Token(
    MAINNET,
    '0xdb333724fAE72b4253FC3d44c8270CBBC86d147b',
    18,
    'CABAG',
    'Cabbage.Cash',
    'https://pancakeswap.finance/',
  ),
  
  complus: new Token(
    MAINNET,
    '0x3711c397B6c8F7173391361e27e67d72F252cAad',
    18,
    'COM',
    'COMPLUS',
    'https://pancakeswap.finance/',
  ),

}



const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }
  
  // return AvalancheTokens
  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
