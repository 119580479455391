export default {
  cake: {
    97: '',
    43114: '0x84F4A604Ae84326A32EF4A80Ce25c55Eb6288C97',
  },
  masterChef: {
    97: '',
    43114: '0xd12ac5d62F9C219AED45f239F03c59201F3790bc',
  },
  sousChef: {
    97: '',
    43114: '0xD27771361E5213291C04faA4D7e1A09d4FD1E36c',
  },
  lotteryV2: {
    97: '',
    43114: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
  },
  multiCall: {
    43114: '0xAcFE622a43c1E651a1d80ECCc7075DE7614Df47c',
    97: '',
  },
  pancakeProfile: {
    43114: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '',
  },
  pancakeRabbits: {
    43114: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '',
  },
  bunnyFactory: {
    43114: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '',
  },
  claimRefund: {
    43114: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    43114: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '',
  },
  bunnySpecial: {
    43114: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '',
  },
  tradingCompetition: {
    43114: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '',
  },
  easterNft: {
    43114: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '',
  },
  cakeVault: {
    43114: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    97: '',
  },
  predictions: {
    43114: '0x516ffd7D1e0Ca40b1879935B2De87cb20Fc1124b',
    97: '',
  },
  chainlinkOracle: {
    43114: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    97: '',
  },
  bunnySpecialCakeVault: {
    43114: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    43114: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    43114: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '',
  },
  farmAuction: {
    43114: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '',
  },
  AnniversaryAchievement: {
    43114: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    97: '',
  },
  nftMarket: {
    43114: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    43114: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '',
  },
  pancakeSquad: {
    43114: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '',
  },
}
