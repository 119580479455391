import { Token } from '@pancakeswap/sdk'
import tokens from 'config/constants/tokens'

const { complus, cabbage } = tokens

interface WarningTokenList {
  [key: string]: Token
}

const SwapWarningTokens = <WarningTokenList>{
  complus, 
  cabbage,
}

export default SwapWarningTokens
