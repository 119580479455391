import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
   {
    pid:0,
    lpSymbol:'$TDEXY-USDT',
    lpAddresses: {
      43114:'0x15087eefa36efdd52541f853a3094fbaa4d20805',
      97:'',
    },
    quoteToken: serializedTokens.usdt,
    token:serializedTokens.cake,
    },
    {
      pid:1,
      lpSymbol:'$TDEXY-WAVAX',
      lpAddresses: {
        97:'',
        43114:'0x2a237f2dc27da09995cf8f79c866622efb5f7ff5',
      },
      quoteToken: serializedTokens.wbnb,
      token:serializedTokens.cake,
    },
    {
      pid:2,
      lpSymbol:'WAVAX-USDT.e',
      lpAddresses: {
        97:'',
        43114:'0xe28984e1EE8D431346D32BeC9Ec800Efb643eef4',
        // 43114:'0x03cCDcfa920E7954Fd682051D0DA6caf1781A397',
      },
      quoteToken: serializedTokens.wbnb,
      token:serializedTokens.usdt_e,
    },
    {
      pid:3,
      lpSymbol:'$TDEXY',
      lpAddresses: {
        43114:'0x15087eefa36efdd52541f853a3094fbaa4d20805',
        97:'',
      },
      quoteToken: serializedTokens.usdt,
      token:serializedTokens.cake,
      isTokenOnly:true,
      },
      {
        pid:4,
        lpSymbol:'WAVAX',
        lpAddresses: {
          43114:'0xe28984e1EE8D431346D32BeC9Ec800Efb643eef4',
          97:'',
        },
        quoteToken: serializedTokens.usdt_e,
        token:serializedTokens.wbnb,
        isTokenOnly:true,
        },

    // {
    //    pid:4,
    //    lpSymbol:'WAVAX-USDC.e',
    //    lpAddresses: {
    //      97:'',
    //      43114:'0xA389f9430876455C36478DeEa9769B7Ca4E3DDB1',
    //    },
    //    quoteToken: serializedTokens.usdc,
    //    token:serializedTokens.wbnb,
    //    exchange: 'TraderJoe'
    // },
    // {
    //    pid:14,
    //    lpSymbol:'WETH.e-WAVAX',
    //    lpAddresses: {
    //      97:'',
    //      43114:'0xfe15c2695f1f920da45c30aae47d11de51007af9',
    //    },
    //    quoteToken: serializedTokens.wbnb,
    //    token:serializedTokens.beth,
    //    exchange: 'TraderJoe'
    // },
    // {
    //    pid:15,
    //    lpSymbol:'WBTC.e-WAVAX',
    //    lpAddresses: {
    //      97:'',
    //      43114:'0xd5a37dc5c9a396a03dd1136fc76a1a02b1c88ffa',
    //    },
    //    quoteToken: serializedTokens.wbnb,
    //    token:serializedTokens.btcb,
    //    exchange: 'TraderJoe'
    // },
    // {
    //    pid:16,
    //    lpSymbol:'USDT.e-USDC.e',
    //    lpAddresses: {
    //      97:'',
    //      43114:'0x2e02539203256c83c7a9f6fa6f8608a32a2b1ca2',
    //    },
    //    quoteToken: serializedTokens.usdc,
    //    token:serializedTokens.usdt,
    //    exchange: 'TraderJoe'
    // },
    // {
    //    pid:17,
    //    lpSymbol:'DAI.e-USDC.e',
    //    lpAddresses: {
    //      97:'',
    //      43114:'0x63abe32d0ee76c05a11838722a63e012008416e6',
    //    },
    //    quoteToken: serializedTokens.usdc,
    //    token:serializedTokens.dai,
    //    exchange: 'TraderJoe'
    // },

]

export default farms
